<template>
    <v-dialog v-model="createOps.dialog" max-width="500px">
        <v-card flat>
            <v-card-title>
                <v-row align="center">
                    <v-col cols="10">
                        <span>Create User</span>
                    </v-col>
                    <v-col cols="2">
                        <div class="float-right">
                            <CustomButton
                                btnIcon="mdi-close"
                                tooltipMsg="Close"
                                @click="hideDialog"
                            />
                        </div>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-divider />

            <v-card-text class="px-4">
                <v-card flat>
                    <v-card-text>
                        <ValidationObserver ref="observer" v-slot="{}">
                            <v-form>
                                <v-row dense>
                                    <v-col cols="12">
                                        <CustomLabel
                                            for="email"
                                            label="Email Address"
                                            required
                                        />
                                        <CustomTextField
                                            id="email"
                                            inputIcon="mdi-email"
                                            inputPlaceholder="Email address"
                                            inputType="email"
                                            :inputValue="createPayload.email"
                                            validationName="email"
                                            validationRules="required"
                                            @input="
                                                updateForm(
                                                    $event,
                                                    'createPayload.email'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>

                                <v-row dense>
                                    <v-col cols="12" sm="4">
                                        <CustomLabel
                                            for="first_name"
                                            label="First Name"
                                            required
                                        />
                                        <CustomTextField
                                            id="first_name"
                                            inputPlaceholder="First name"
                                            :inputValue="
                                                createPayload.first_name
                                            "
                                            validationName="first_name"
                                            validationRules="required"
                                            @input="
                                                updateForm(
                                                    $event,
                                                    'createPayload.first_name'
                                                )
                                            "
                                        />
                                    </v-col>

                                    <v-col cols="12" sm="4">
                                        <CustomLabel
                                            for="middle_name"
                                            label="Middle Name"
                                        />
                                        <CustomTextField
                                            id="middle_name"
                                            inputPlaceholder="Middle name"
                                            :inputValue="
                                                createPayload.middle_name
                                            "
                                            @input="
                                                updateForm(
                                                    $event,
                                                    'createPayload.middle_name'
                                                )
                                            "
                                        />
                                    </v-col>

                                    <v-col cols="12" sm="4">
                                        <CustomLabel
                                            for="last_name"
                                            label="Last Name"
                                            required
                                        />
                                        <CustomTextField
                                            id="last_name"
                                            inputPlaceholder="Last name"
                                            :inputValue="
                                                createPayload.last_name
                                            "
                                            validationName="last_name"
                                            validationRules="required"
                                            @input="
                                                updateForm(
                                                    $event,
                                                    'createPayload.last_name'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>

                                <v-row dense>
                                    <v-col>
                                        <CustomLabel
                                            for="display_name"
                                            label="Display Name"
                                            required
                                        />
                                        <CustomTextField
                                            id="display_name"
                                            inputPlaceholder="Richard M. Hammond"
                                            :inputValue="
                                                createPayload.display_name
                                            "
                                            validationName="display_name"
                                            validationRules="required"
                                            @input="
                                                updateForm(
                                                    $event,
                                                    'createPayload.display_name'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>

                                <v-row dense>
                                    <v-col>
                                        <CustomLabel
                                            for="role"
                                            label="Role"
                                            required
                                        />
                                        <CustomSelectField
                                            id="role"
                                            inputIcon="mdi-account-cog"
                                            inputPlaceholder="Role"
                                            :optionItems="userRoles"
                                            optionText="type"
                                            optionValue="id"
                                            @change="
                                                updateForm(
                                                    $event,
                                                    'createPayload.role'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>

                                <v-row class="mt-4">
                                    <v-col>
                                        <CustomButton
                                            btnLabel="Cancel"
                                            tooltipMsg="Cancel"
                                            @click="hideDialog"
                                        />
                                        <CustomButton
                                            btnColour="primary"
                                            :btnIsLoading="createOps.isLoading"
                                            btnLabel="Save"
                                            tooltipMsg="Save"
                                            @click="createItems(createPayload)"
                                        />
                                    </v-col>
                                </v-row>
                            </v-form>
                        </ValidationObserver>
                    </v-card-text>
                </v-card>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { userRoles } from "@/services/OptionMappers";

extend("required", {
    ...required,
    message: "Field cannot be empty.",
});

export default {
    computed: {
        ...mapState("usersAdminMgmt", ["createOps", "createPayload"]),
    },
    data: () => ({
        userRoles: userRoles,
    }),
    methods: {
        async createItems(payload) {
            let valid = await this.$refs.observer.validate();

            if (!valid) {
                return;
            }

            let response = await this.$store.dispatch(
                "usersAdminMgmt/createItems",
                payload
            );

            if (response) {
                this.$refs.observer.reset();
            }
        },
        hideDialog() {
            this.$store.commit("usersAdminMgmt/HIDE_CREATE_OPS_DIALOG");
            this.$store.commit("usersAdminMgmt/FLUSH_FIELD", "createPayload");
            this.$refs.observer.reset();
        },
        updateForm(event, form) {
            if (event instanceof InputEvent) {
                this.$store.commit("usersAdminMgmt/UPDATE_FORM", {
                    payload: {
                        form: form,
                        value: event.target.value,
                    },
                });
                return;
            } else if (
                event instanceof String ||
                typeof event == "boolean" ||
                typeof event == "number" ||
                typeof event == "string"
            ) {
                this.$store.commit("usersAdminMgmt/UPDATE_FORM", {
                    payload: {
                        form: form,
                        value: event,
                    },
                });
                return;
            } else {
                return;
            }
        },
    },
    name: "DialogUserCreate",
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>
